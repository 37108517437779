import * as React from 'react';
import { MavienSelectInputProps } from '../../@types/appTypes';
import { addClassNames } from '../../utils/functions';


const MavienSelectInput = (props: MavienSelectInputProps) => {

    const { label, extrainputclass, extralabelclass } = props

    let primary_class = 'text-[#030424] placeholder-[#CACACA] bg-[#fff] focus:border-[#E7E7E7] border-[#E7E7E7] focus:text-[#030424] border-2 p-2 h-[54px] font-medium rounded-md text-[18px] flex items-center h-[48px] min-w-[200px]'

    return (
        <div>
            {props.label ? <label className={
                addClassNames(
                    'mb-[8px] font-outift text-[18px]',
                    extralabelclass ? extralabelclass : ''
                )
            }>{props.label}</label> : undefined}

            <select
                className={
                    addClassNames(
                        'w-full font-outift hover:border-[#AAAAAA]',
                        primary_class,
                        extrainputclass ? extrainputclass : '',
                    )}
                {...props}
            />
        </div>
    )
}

export default MavienSelectInput
import * as React from 'react';

interface Props {
    onClick: () => any;
    isOpen: boolean
}

const Hamburger = (props: Props) => {
    const { isOpen, onClick } = props

    const genericHamburgerLine = `h-[2px] w-[18px] mb-[5px] rounded-full bg-[#030424] transition ease transform duration-300`;

    return (
        <div
            className="flex flex-col h-[fit-content] w-12 justify-center items-center group cursor-pointer"
            onClick={props.onClick}
        >
            <div
                className={`${genericHamburgerLine} ${isOpen
                    ? "rotate-45 translate-y-[7px] opacity-100"
                    : "opacity-100"
                    }`}
            />
            <div
                className={`${genericHamburgerLine} ${isOpen ? "opacity-0" : "opacity-100"
                    }`}
            />
            <div
                className={`${genericHamburgerLine} ${isOpen
                    ? "-rotate-45 -translate-y-[7px] opacity-100"
                    : "opacity-100"
                    }`}
            />
        </div>
    );
};

export default Hamburger
import { userApi } from "./userApi";

export const dashboardApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardSummary: builder.query({
      query: () => {
        return {
          url: "dashboard/summary",
          method: "GET",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetDashboardSummaryQuery } = dashboardApi;

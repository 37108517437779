import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1.1em"
        height="1em"
        fill="none"
        {...props}
    >
        <path
            stroke="#6640FF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M15.63 3.457a4.125 4.125 0 0 0-5.834 0L9 4.252l-.795-.795A4.126 4.126 0 0 0 2.37 9.292l.795.795L9 15.922l5.835-5.835.795-.795a4.127 4.127 0 0 0 0-5.835Z"
        />
    </svg>
)
export default SvgComponent

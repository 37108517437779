import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={25}
        height={25}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >


        <g
            stroke="#C5CAD2"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="scale(0.8)"
        >
            <path d="M11.003 20.167a9.167 9.167 0 1 0 0-18.333 9.167 9.167 0 0 0 0 18.333ZM11 14.667V11M11 7.334h.01" />
        </g>
        <defs>
            <clipPath id="a">
                {/* <path fill="#fff" d="M0 0h22v22H0z" /> */}
            </clipPath>
        </defs>
    </svg>
)

export default SvgComponent

import { Transition } from '@headlessui/react';
import * as React from 'react'

import Hamburger from './Hamburger';

import { FiLogOut } from 'react-icons/fi'
import { Field, FormikProvider, useFormik } from 'formik';
import { useGetDashboardSummaryQuery } from '../../store/rtk-query/dashboardApi';
import { MobileFilterProps } from '../../@types/appTypes';
import { orderByFilterOptions } from '../../common/variables';

const orderBy = orderByFilterOptions

const categories = [
    'Réseaux sociaux',
    'Service en ligne',
    'Boite-mail',
    'Gaming',
    'Banque en ligne'
]

const grades = [
    'Grade A',
    'Grade B',
    'Grade C',
    'Grade D',
    'Grade E',
]

let input_class = "w-4 h-4 text-mavien-purple mr-3 bg-gray-100 border-gray-300 rounded"

const FilterMenu = (props: MobileFilterProps) => {
    const { data: dashboardData, isLoading: dashboardLoading } = useGetDashboardSummaryQuery({})

    const initialValues = props.initialValues;
    const formik = props.formik

    const { activeCats, activeGrades, activeSort } = formik.values

    const getGradeCount = (grade: string) => {
        let formatted = grade?.toLowerCase()?.replace('grade', '')?.trim();

        const ratingsCounts = dashboardData?.ratingsCounts

        let count = ratingsCounts?.[formatted?.toUpperCase()] || 0;
        return count
    }

    const renderCategories = () => {
        return (
            <div className='space-y-2 mt-[40px]'>
                <div className='font-bold'>Catégories</div>
                {categories.map((item) => {
                    const checked = activeCats.some((cat) => cat == item)
                    return <div
                        key={item}
                        className='flex items-center h-[30px] rounded-lg px-2'
                        style={{
                            backgroundColor: checked ? '#5BCEFF1A' : 'unset',
                            fontWeight: checked ? '600' : 'unset',
                        }}
                        onClick={() => {
                            formik.setFieldValue(
                                'activeCats',
                                checked
                                    ? activeCats.filter(grade => grade !== item)
                                    : [...activeCats, item]
                            );
                        }}
                    >
                        <input
                            type="checkbox"
                            checked={checked}
                            onChange={() => { }}
                            className={input_class} />
                        <div>{item}</div>
                        <div className='ml-[auto] bg-[#5BCEFF33] rounded-2xl flex justify-center  '>7</div>
                    </div>
                })}
            </div>
        )
    }

    return (
        <Transition
            show={props.open}
            enter="transition ease duration-700 transform"
            enterFrom="opacity-0 -translate-x-full"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease duration-1000 transform"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 -translate-x-full"
            as={React.Fragment}
        >

            <div style={{ height: '100vh', zIndex: 1000 }} className='fixed overflow-x-scroll top-[76px] left-0 right-0 z-10  bg-white flex flex-col items-center mt-[-76px] pb-10'>
                <div className=' w-full justify-between flex items-center px-4 bg-[#F3F0FF] !h-[76px] py-[18px] ml-[auto] sm:hidden'>
                    <div className='font-semibold text-[20px] '>Filtres</div>
                    <Hamburger
                        onClick={props.onClick}
                        isOpen={props.open}
                    />
                </div>
                <div className='mt-5 mx-10 self-stretch'>
                    <FormikProvider value={formik}>
                        <div className='space-y-2'>
                            <div className='font-bold mt-10'>Trier par</div>
                            {orderBy.map((item, index) => {
                                const selected = activeSort == item.id
                                return <div
                                    onClick={() => {
                                        formik.setFieldValue(
                                            'activeSort',
                                            item.id
                                        );
                                    }}
                                    key={item.id}
                                    style={{
                                        backgroundColor: selected ? '#F3F0FF' : 'unset',
                                        fontWeight: selected ? '500' : 'unset',
                                    }}
                                    className='h-[30px] rounded-lg flex items-center pl-2 cursor-pointer'
                                >
                                    {item.label}
                                </div>
                            })}

                        </div>
                        {
                            // renderCategories()
                        }
                        <div className='space-y-2 mt-[40px]'>
                            <div className='font-bold'>Grades</div>
                            {grades.map((item) => {
                                const checked = activeGrades.some((grade) => grade == item)

                                return <div
                                    key={item}
                                    className='flex items-center h-[30px] rounded-lg px-2'
                                    style={{
                                        backgroundColor: checked ? '#F3F0FF' : 'unset',
                                        fontWeight: checked ? '600' : 'unset',
                                    }}
                                    onClick={() => {
                                        formik.setFieldValue(
                                            'activeGrades',
                                            checked
                                                ? activeGrades.filter(grade => grade !== item)
                                                : [...activeGrades, item]
                                        );
                                    }}
                                >
                                    <input
                                        id="default-checkbox"
                                        checked={checked}
                                        onChange={() => { }}
                                        type="checkbox"
                                        className={input_class} />
                                    <div>{item}</div>
                                    <div className='ml-[auto] rounded-2xl flex justify-center w-[32px] font-[500]'>
                                        {getGradeCount(item)}
                                    </div>
                                </div>
                            })}
                        </div>
                    </FormikProvider>
                </div>
            </div>

        </Transition>
    )
}

export default FilterMenu
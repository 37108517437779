import { Field, Form, FormikProvider, useFormik } from 'formik'
import * as React from 'react'

import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { CompleteRegFormValues } from '../@types/appTypes'
import AppButton from '../components/UI/AppButton'
import MavienTextInput from '../components/UI/MavienTextInput'
import { useGetProfileQuery, useUpdateProfileMutation } from '../store/rtk-query/profileApi'

import * as Yup from 'yup'
import MavienSelectInput from '../components/UI/MavienSelectInput'
import Spinner from '../components/UI/Spinner'

const CompleteReg = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const [name, setName] = React.useState<string>('')

    const [updateProfile, updateProfileState] = useUpdateProfileMutation()

    const { data: profileData, isLoading: profileLoading } = useGetProfileQuery({})

    const initialValues: CompleteRegFormValues = {
        name: '',
        userSource: '',
        noOfMailAddress: ''
    }

    const formValidationSchema = Yup.object({
        name: Yup.string().required('Required'),
        userSource: Yup.string().required('Required'),
        noOfMailAddress: Yup.string().required('Required'),
    })

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: formValidationSchema,
        onSubmit: (values) => {
            submit(values)
        }
    })

    const noOfMailAddressOpts = [
        '1',
        '2',
        '3',
        '4',
        '5 et plus'
    ]
    const userSourceOpts = [
        'Recherche Google',
        'Réseaux sociaux',
        'Par un proche',
        'Autre'
    ]

    const shouldRedirectToNextPage: boolean = React.useMemo(() => {
        let shouldRedirect = false;
        if (!profileData) {
            shouldRedirect = false;
            return shouldRedirect;
        }

        /**
     * User must have submitted :
     *  - user source
     *  - username
     *  - no of email addresses
     */
        const isProfileComplete = profileData?.user?.username && profileData?.user?.userSource && profileData?.user?.numberOfMailAddresses

        if (isProfileComplete) {
            shouldRedirect = true
        }

        return shouldRedirect;
    }, [profileData])

    const submit = async (values: CompleteRegFormValues) => {
        const res: any = await updateProfile({
            username: values.name,
            userSource: values.userSource,
            numberOfMailAddresses: values.noOfMailAddress
        })

        console.log('res ', res)

        if (!res?.data) {
            toast.error("Error during submit", {
                position: "top-right",
                autoClose: 1500,
            })
        }

        // navigate('/dashboard')
    }

    /**
     * if loading render blank
     */
    if (profileLoading) {
        return <div />
    }


    if (shouldRedirectToNextPage) {
        return <Navigate to="/dashboard" replace state={{ from: location }} />;
    }


    return <div className='flex flex-col justify-around '>
        <div className='flex flex-col justify-around items-center space-y-4 sm:space-y-6 mx-[35px] mt-10 sm:mt-16'>
            <div className='text-[#0304244D] text-[18px] font-outfit'>
                Étape 5
            </div>
            <div className='space-y-3 self-stretch flex flex-col items-center'>
                <div className='text-center text-[32px] sm:text-[30px] md:text-[40px] sm:max-w-[65%] font-[500] font-outfit md:leading-[38px]'>
                    Votre espace <span className='text-mavien-purple'>est prêt!</span>
                </div>
            </div>
            <FormikProvider value={formik}>
                <Form>
                    <div className='w-[80vw] sm:w-[350px] space-y-[30px]'>
                        <Field
                            name="name"
                            placeholder='Prénom'
                            label={<div className='text-[18px]' >
                                Comment pouvous-nous vous appeller ? <span className='text-[#EA412C] mb-1'>*</span>
                            </div>}
                            as={MavienTextInput}
                            extrainputclass={'!rounded-[40px] !pl-[20px] mt-[8px]'}
                        />
                        <Field
                            name="userSource"
                            label={<div className='text-[18px]' >
                                Comment avez-vous connu notre service ? <span className='text-[#EA412C] mb-1'>*</span>
                            </div>}
                            as={MavienSelectInput}
                            extrainputclass={'!rounded-[40px] !pl-[20px] mt-[8px]'}
                        >
                            <option value='' hidden disabled></option>
                            {userSourceOpts?.map((item) => {
                                return (
                                    <option key={item} value={item}>{item}</option>
                                )
                            })}
                        </Field>

                        <Field
                            name="noOfMailAddress"
                            label={<div className='text-[18px]' >
                                Combien d’adresses mails avez-vous ? <span className='text-[#EA412C] mb-1'>*</span>
                            </div>}
                            as={MavienSelectInput}
                            extrainputclass={'!rounded-[40px] !pl-[20px] mt-[8px]'}
                        >
                            <option value='' hidden disabled></option>
                            {noOfMailAddressOpts?.map((item) => {
                                return (
                                    <option key={item} value={item}>{item}</option>
                                )
                            })}
                        </Field>

                        <AppButton
                            title='Découvrir ma vie numérique'
                            extraclass='w-full'
                            type="submit"
                            disabled={!formik.isValid}
                        />
                        {updateProfileState.isLoading && <Spinner />}
                    </div>
                </Form>
            </FormikProvider>
        </div>
    </div>
}

export default CompleteReg
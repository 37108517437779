export const orderByFilterOptions = [
  {
    id: "pertinence",
    label: "Pertinence",
  },
  {
    id: "AtoE",
    label: "Les mieux notés",
  },
  {
    id: "EtoA",
    label: "Les moins bien notés",
  },
  {
    id: "ascAlpha",
    label: "Ordre alphabétique croissant",
  },
  {
    id: "descAlpha",
    label: "Ordre alphabétique décroissant",
  },
];

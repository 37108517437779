import * as React from 'react'
import { addClassNames } from '../../utils/functions';

import * as JsSvgs from '../../assets/js_svgs'
import { useGetDashboardSummaryQuery } from '../../store/rtk-query/dashboardApi';
import ToolTip from '../UI/Tooltip';
import useResponsive from '../../hooks/useResponsive';

import { SlClose } from 'react-icons/sl'

import myOpinionImg from '../../assets/images/myOpinion.png'

interface MetricsProps {
    profileData: any;
    onShowForm: () => void;
    isFormSubmitted: boolean;
}

const DashboardMetrics = (props: MetricsProps) => {

    const { profileData } = props

    const { data: dashboardData, isLoading: dashboardLoading } = useGetDashboardSummaryQuery({})

    const { isMobile, isTablet, isDesktop } = useResponsive();

    const getAccountScoreContent = (grade: string) => {
        let content = {
            textColor: '#FFC800',
            textColorClass: 'text-[#FFC800]',
            cardColor: 'bg-[#FDEDC1]',
            text: ''
        }

        if (!grade) return content;

        if (grade == 'A') {
            content = {
                textColor: '#2DAD4C',
                textColorClass: 'text-[#2DAD4C]',
                cardColor: 'bg-[#E5FFEC]',
                text: 'Vos données sont sécurisés'
            }
        }
        if (grade == 'B') {
            content = {
                textColor: '#33A5DD',
                textColorClass: 'text-[#33A5DD]',
                cardColor: 'bg-[#EAF8FF]',
                text: 'L’essentiel de vos données sont sécurisés '
            }
        }
        if (grade == 'C') {
            content = {
                textColor: '#FFC800',
                textColorClass: 'text-[#FFC800]',
                cardColor: 'bg-[#FDEDC1]',
                text: 'Vos données sont exposés à des risques '
            }
        }
        if (grade == 'D') {
            content = {
                textColor: '#F78E40',
                textColorClass: 'text-[#F78E40]',
                cardColor: 'bg-[#FFEEE1]',
                text: 'L’essentiel de vos données sont exposés à des risques '
            }
        }
        if (grade == 'E') {
            content = {
                textColor: '#D94646',
                textColorClass: 'text-[#D94646]',
                cardColor: 'bg-[#FFEBEB]',
                text: 'L’essentiel de vos données sont exposés à des risques '
            }
        }
        if (grade == 'X') {
            content = {
                textColor: '#7E7E7E',
                textColorClass: 'text-[#7E7E7E]',
                cardColor: 'bg-[#F6F6F6]',
                text: 'Votre grade est en cours de calcul'
            }
        }

        return content;


    }


    const gradeCardStyle = "flex items-center h-[55px]  gap-3 rounded-md px-[16px] py-[8px]";
    const { text, textColorClass, cardColor, textColor } = getAccountScoreContent(dashboardData?.averageGrade)

    return (
        <div className={
            addClassNames(
                'flex flex-wrap sm:flex-nowrap  w-full',
                //if form has been submitted , add max width for metrics
                props.isFormSubmitted ? 'max-w-[600px]' : ''
            )
        }>
            <div className='bg-[#F3F0FF] mr-[18px] md:mr-[19px] rounded-md flex-1 sm:min-w-[36.9%] p-2 pl-[16px] sm:pl-[30px] pb-[22px] sm:pb-[40px]'>
                <div className='w-[fit-content] ml-[auto]'>
                    <ToolTip
                        triggerStrict={true}
                        button={<JsSvgs.InfoIcon className='p-1' />}
                        content={<div className=''>
                            Il s’agit du nombre de compte que vous possédez et qui sont rattachés à votre adresse mail.
                        </div>}
                        panelStyles={{
                            width: 200,
                            left: isMobile ? -140 : -180
                        }}
                        trigger={isMobile ? "click" : "hover"}
                    />

                </div>
                <div className='space-y-[10px]'>
                    <JsSvgs.RadioIcon />
                    <div className='font-[600] text-[30px] text-mavien-purple md:leading-[38px]'>
                        {dashboardData?.totalCount || 0}
                    </div>
                    <div className='font-outfit leading-[20px]'>
                        comptes détectés sur internet
                    </div>
                </div>
            </div>

            <div className={
                addClassNames(cardColor, 'rounded-md sm:mr-[19px] flex-1 sm:min-w-[36.9%] p-2 pl-[16px] sm:pl-[30px] pb-[22px] sm:pb-[40px]')
            }>
                <div className='w-[fit-content] ml-[auto]'>

                    <ToolTip
                        triggerStrict={true}
                        button={<JsSvgs.InfoIcon
                            className='p-1'
                        />}
                        content={<div className='space-y-3 py-2 px-2'>
                            <div>Il s’agit de la moyenne des grades de vos comptes numériques.</div>

                            <div>Cette notation permet de vous rendre compte du risque d’exploitation de vos données personnelles :</div>
                            <div className='space-y-[8px] mt-[16px]'>
                                <div className={
                                    addClassNames(`${gradeCardStyle}`, getAccountScoreContent('A').cardColor)
                                }>
                                    <span className={
                                        addClassNames(
                                            'font-semibold text-[40px] font-outfit',
                                            `text-[${getAccountScoreContent('A').textColor}]`
                                        )
                                    }>A</span>
                                    <span className='leading-[16px] text-[#030424]'>Vos données sont sécurisés</span>
                                </div>
                                <div className={
                                    addClassNames(`${gradeCardStyle}`, getAccountScoreContent('B').cardColor)
                                }>
                                    <span className={
                                        addClassNames(
                                            'font-semibold text-[40px] font-outfit',
                                            `text-[${getAccountScoreContent('B').textColor}]`
                                        )
                                    }>B</span>
                                    <span className='leading-[16px] text-[#030424]'>L’essentiel de vos données sont sécurisés </span>
                                </div>
                                <div className={
                                    addClassNames(`${gradeCardStyle}`, getAccountScoreContent('C').cardColor)
                                }>
                                    <span className={
                                        addClassNames(
                                            'font-semibold text-[40px] font-outfit',
                                            `text-[${getAccountScoreContent('C').textColor}]`
                                        )
                                    }>C</span>
                                    <span className='leading-[16px] text-[#030424]'>Vos données sont exposés à des risques </span>
                                </div>
                                <div className={
                                    addClassNames(`${gradeCardStyle}`, getAccountScoreContent('D').cardColor)
                                }>
                                    <span className={
                                        addClassNames(
                                            'font-semibold text-[40px] font-outfit',
                                            `text-[${getAccountScoreContent('D').textColor}]`
                                        )
                                    }>D</span>
                                    <span className='leading-[16px] text-[#030424]'>L’essentiel de vos données sont exposés à des risques </span>
                                </div>
                                <div className={
                                    addClassNames(`${gradeCardStyle}`, getAccountScoreContent('E').cardColor)
                                }>
                                    <span className={
                                        addClassNames(
                                            'font-semibold text-[40px] font-outfit',
                                            `text-[${getAccountScoreContent('E').textColor}]`
                                        )
                                    }>E</span>
                                    <span className='leading-[16px] text-[#030424]'>Prenez des mesures pour sécuriser vos données</span>
                                </div>

                            </div>
                        </div>}
                        panelStyles={{
                            width: 350,
                            left: -330
                        }}
                        trigger={isMobile ? "click" : "hover"}
                    />
                </div>
                <div className='space-y-[10px]'>
                    <JsSvgs.MetricIcon
                        stroke={textColor}
                    />
                    <div className={
                        addClassNames(
                            'font-[600] text-[30px] md:leading-[38px]',
                            textColorClass
                        )
                    }>Grade {dashboardData?.averageGrade}</div>
                    <div className='leading-[20px]'>
                        {text}
                    </div>
                </div>
            </div>
            {
                props.isFormSubmitted ?
                    undefined
                    : <div
                        onClick={() => props.onShowForm()}
                        className={
                            addClassNames(
                                'cursor-pointer flex sm:flex-wrap justify-between w-full sm:grow bg-mavien-purple rounded-md p-[20px]',
                                'mt-[18px] sm:mt-[0]'
                            )
                        }>
                        <div className='h-[59px] sm:h-[90px] sm:mt-[10px]'>
                            <img className='h-full w-full object-contain' src={myOpinionImg} />
                        </div>
                        <div className='sm:mt-[8px] sm:w-full'>
                            <div className='text-[14px] text-white mb-[6px] sm:hidden'>Quelques questions pour nous aider</div>
                            <div className='text-white text-[16px] font-[500] flex items-center gap-[9px] ml-[auto] w-[fit-content] sm:w-[auto]'>
                                <span>Donner mon avis</span>
                                <span><JsSvgs.ChevronRight /></span>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default DashboardMetrics
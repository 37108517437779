import { Popover, Transition } from '@headlessui/react'
import * as React from 'react'
import { AppContext } from '../../context/AppContext';
import useResponsive from '../../hooks/useResponsive';

type TriggerEnum = 'click' | 'hover'

interface Props {
    button?: JSX.Element;
    content?: JSX.Element;
    open?: boolean;
    panelStyles?: React.CSSProperties;
    trigger?: TriggerEnum;
    /**
     * to determine if the tooltip should trigger strictly on hover over button
     * or strictly on clicking button
     *
     * tapping outside has no effect
     */
    triggerStrict?: boolean
}

const ToolTip = (props: Props) => {
    let timeout: NodeJS.Timeout
    const [buttonId, setButtonId] = React.useState<string>('')
    const timeoutDuration = 250

    const popoverContainerRef = React.useRef<HTMLDivElement | null>(null) // useRef<HTMLButtonElement>(null)
    const buttonRef = React.useRef<HTMLButtonElement | null>(null) // useRef<HTMLButtonElement>(null)
    const [openState, setOpenState] = React.useState(false)

    const { isMobile } = useResponsive()

    const mounted = React.useRef(false)

    React.useEffect(() => {
        let button_id = `tooltip-button-${(Math.random() * 1000).toFixed(0)}`

        setButtonId(button_id)
    }, [])


    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    })


    const handleClickOutside = (event: any) => {
        /** close tooltip if another tooltip trigger button is tapped */
        const allTriggerButtons = document.querySelectorAll('.tooltip-headlessui-button')
        allTriggerButtons.forEach((button) => {
            if (button.contains(event.target)) {
                setOpenState(false) //close tooltip
            }
        })

        if (buttonRef.current && !buttonRef.current.contains(event.target)) {
            event.stopPropagation()
        }

        //if trigger_strict == true, ignore clicks outside for devices > 768px
        if (props.triggerStrict && props.trigger !== undefined) {
            if (!isMobile) {
                return
            }
        };

        /**
         * determine if clicked element is not in the popover dom tree
         * - clicks within popover tree should be ignored
         */
        if (!popoverContainerRef.current?.contains(event.target)) {
            //close Tooltip if its not in the dom tree
            setOpenState(false)
        }
    }


    const toggleMenu = (open: boolean) => {
        // log the current open state in React (toggle open state)
        setOpenState(!open)
        // toggle the menu by clicking on buttonRef
        buttonRef?.current?.click()
    }

    // Open the menu after a delay of timeoutDuration
    const onHover = (open: boolean, action: string) => {
        if (props.trigger) {
            if (props.trigger !== "hover") return
        }
        // whenever user mouse leaves div
        if (open && openState && action === "onMouseLeave") {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                const currentOpen = open && openState
                toggleMenu(currentOpen)
            }, timeoutDuration)
        }

        // whenever user mouse enters div
        if (!open && !openState && action === "onMouseEnter") {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                const currentOpen = open && openState
                toggleMenu(currentOpen)
            }, timeoutDuration)
        }
    }

    const handleClickButton = (open: boolean) => {
        if (props.trigger) {
            if (props.trigger == "hover") return
        }

        setOpenState(!open) // toggle open state in React state
        clearTimeout(timeout) // stop the hover timer if it's running
    }


    return (
        <Popover
            ref={popoverContainerRef}
            className="relative">
            {(
                // { open }: { open: boolean }
            ) => {
                /** use manually managed open state instead of <PopOver/> default open prop */
                let open = openState;
                if (props.open !== undefined) {
                    //use open props before checking the inbuilt open props
                    open = props.open
                }

                return <div
                    onMouseEnter={() => onHover(open, "onMouseEnter")}
                    onMouseLeave={() => onHover(open, "onMouseLeave")}
                    className="flex flex-col items-center justify-center"
                >
                    <button
                        ref={buttonRef}
                        onClick={() => {
                            if (props.trigger !== "click") return
                            handleClickButton(open)
                        }}
                        className='tooltip-headlessui-button'
                    >
                        {props.button}
                    </button>
                    <Transition
                        show={open}
                        as={React.Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel
                            style={{
                                ...props.panelStyles
                            }}
                            static
                            className="z-50 min-w-[200px] absolute bg-white rounded-md drop-shadow-md top-[20px] p-4">
                            {props.content}
                        </Popover.Panel>
                    </Transition>
                </div>
            }
            }
        </Popover >
    )
}

export default ToolTip
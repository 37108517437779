import * as React from 'react'

interface ToolTipItem {
    [key: string]: {
        open: boolean
    }
}

interface contextType {
    menuOpen: boolean;
    setMenuOpen: (val: boolean) => void;
}

export const AppContext = React.createContext<contextType>({
    menuOpen: false,
    setMenuOpen(val) {

    },
});


const AppContextProvider = (props: any) => {

    const [menuOpen, setMenuOpen] = React.useState<boolean>(false)


    return (
        <AppContext.Provider
            value={{
                menuOpen,
                setMenuOpen,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;

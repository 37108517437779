import * as React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../components/Header'
import { AppContext } from '../context/AppContext'

const DefaultLayout = () => {
    const { menuOpen, setMenuOpen } = React.useContext(AppContext)


    return <div className='min-h-[100vh] flex flex-col'>
        <Header />
        <main
            style={{
                minHeight: 'calc( 100vh - 76px )',
                ...(menuOpen && { position: 'fixed' })
            }}
            className='flex flex-col flex-1 bg-[#F9F9F9]'>
            <Outlet />
        </main>
    </div>
}

export default DefaultLayout
import * as React from 'react';

const StickyDiv = (props: any) => {
    const topDivRef = React.useRef<any>();
    const pseudoTopDivRef = React.useRef<any>();

    const originalTopRef = React.useRef<number>(0);

    const isStickyRef = React.useRef<boolean>(false);

    // Determine if top percent div is sticky
    const [isSticky, setIsSticky] = React.useState<boolean>(false);

    const handleScroll = () => {
        const { top } = topDivRef.current.getBoundingClientRect();
        const { top: pseudoTop } = pseudoTopDivRef.current.getBoundingClientRect();

        if (top <= 0) {
            if (!isStickyRef.current) {
                setIsSticky(true);
                isStickyRef.current = true
            }
        }
        if (pseudoTop >= 0) {
            setIsSticky(false);
            isStickyRef.current = false
        }

    };

    React.useEffect(() => {
        originalTopRef.current = topDivRef.current.getBoundingClientRect().top;

        // Attach the scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div
                ref={topDivRef}
                className={`${isSticky ? 'fixed top-0 left-0 w-full bg-white shadow-md z-40' : ''
                    }`}
            >
                {props.children}
            </div>
            <div ref={pseudoTopDivRef} />
        </>
    );
};

export default StickyDiv;

import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M21 20h-7M10 20H3M21 12h-9M8 12H3M21 4h-5M12 4H3M14 23v-6M8 15V9M16 7V1"
            stroke="#BBB"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default SvgComponent

// @ts-nocheck
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuth } from "@firebase/auth";
import { updateToken } from "../reducers/userSlice";

const BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/";

const logoutSuccess = () => {
  return {
    type: "user/logout",
  };
};

const refreshFirebaseToken = async (dispatch) => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const token = await currentUser?.getIdToken(true);
  dispatch(
    updateToken({
      idToken: token,
    })
  );
};

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const userState = getState().user;
    const idToken = userState?.idToken;

    if (idToken) {
      headers.set("x-id-token", `${idToken}`);
    }
    return headers;
  },
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  const { dispatch, getState } = api;

  if (result.error && result.error.status === 401) {
    // dispatch(logoutSuccess());
    await refreshFirebaseToken(dispatch);
  }
  return result;
};

import { userApi } from "./userApi";

export const accountApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getAccounts: builder.query({
      query: ({ queryParams }) => {
        return {
          url: "account",
          method: "GET",
          params: { ...queryParams },
        };
      },
      providesTags: ["Account"],
    }),
    pullMailDomains: builder.mutation({
      query: (body) => {
        return {
          url: "account/pull",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Account"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetAccountsQuery, usePullMailDomainsMutation } = accountApi;

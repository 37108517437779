import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#a)">
            <path
                d="M24 12c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12Z"
                fill="url(#b)"
            />
            <path
                opacity={0.1}
                d="M21.031 4.106c-1.749 3.211-4.223 6.524-7.315 9.615-3.091 3.092-6.404 5.561-9.61 7.31a11.427 11.427 0 0 1-.587-.546 12 12 0 1 1 16.966-16.97c.19.19.372.386.546.59Z"
                fill="#fff"
            />
            <path
                d="m11.063 8.784-1.59 3.946-1.54-3.947a.303.303 0 0 0-.28-.193h-2.14a.302.302 0 0 0-.28.415l2.88 7.07-.736 1.821a.301.301 0 0 0 .28.42h2.067a.3.3 0 0 0 .28-.188l3.686-9.117a.301.301 0 0 0-.27-.415h-2.08a.3.3 0 0 0-.277.188ZM16.136 5.872a.304.304 0 0 1 .284-.188h2.07a.3.3 0 0 1 .28.414l-2.192 5.388a.301.301 0 0 1-.28.189h-2.067a.301.301 0 0 1-.28-.415l2.185-5.388ZM14.395 15.938a1.672 1.672 0 1 0 0-3.344 1.672 1.672 0 0 0 0 3.344Z"
                fill="#fff"
            />
        </g>
        <defs>
            <radialGradient
                id="b"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(12 0 0 12 12 12)"
            >
                <stop stopColor="#6401D9" />
                <stop offset={0.99} stopColor="#5201B3" />
            </radialGradient>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
)

export default SvgComponent

import React from 'react';
import logo from './logo.svg';
import './App.css';
//
import MainRouter from './router/MainRouter';
//
import { Auth, getAuth, getRedirectResult, GoogleAuthProvider, onAuthStateChanged } from '@firebase/auth';
import { useDispatch } from 'react-redux';
import { updateToken } from './store/reducers/userSlice';

function App() {
  const dispatch = useDispatch()

  const authListener = () => {
    const auth = getAuth();

    onAuthStateChanged(auth, async (currentUser) => {

      if (currentUser) {

        const token = await currentUser.getIdToken();
        dispatch(
          updateToken({
            idToken: token,
            refreshToken: currentUser.refreshToken,
            userData: currentUser
          })
        );
      }
    });
  };


  React.useEffect(() => {
    // getIdToken();
    authListener();
  }, []);

  return (
    <MainRouter />
  );
}

export default App;

import { userApi } from "./userApi";

export const profileApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: () => {
        return {
          url: "profile",
          method: "GET",
        };
      },
      providesTags: ["Profile"],
    }),
    updateProfile: builder.mutation({
      query: (body) => {
        return {
          url: "profile",
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Profile"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetProfileQuery, useUpdateProfileMutation } = profileApi;

import { Transition } from '@headlessui/react';
import * as React from 'react'

import Hamburger from './Hamburger';

import { FiLogOut } from 'react-icons/fi'

import { getAuth, signOut } from "@firebase/auth";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/reducers/userSlice';

interface Props {
    onClick: () => any;
    isOpen: boolean
}

const MobileMenu = (props: Props) => {
    const dispatch = useDispatch()

    const userData = useSelector((state: any) => state.user.userData)

    const auth = getAuth();

    const onLogout = () => {
        // const auth = getAuth();
        signOut(auth).then(() => {
            //close menu
            props.onClick()
            // Sign-out successful.
            dispatch(logout())
        }).catch((error) => {
            // An error happened.
            toast.error("Error during signout", {
                position: "top-right",
                autoClose: 1500,
            })
        });
    }


    return (
        <div className='ml-[auto] sm:hidden'>
            <Hamburger
                onClick={props.onClick}
                isOpen={props.isOpen}
            />
            <Transition
                show={props.isOpen}
                enter="transition ease duration-700 transform"
                enterFrom="opacity-0 -translate-x-full"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease duration-1000 transform"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 -translate-x-full"
                as={React.Fragment}
            >
                <div style={{ height: 'calc( 100vh - 76px )', }} className='absolute top-[76px] left-0 right-0 z-10  bg-white flex flex-col items-center pt-[13%]'>
                    <div className='rounded-[50%] bg-[#D9D9D9] h-[130px] w-[130px]'>
                        {userData?.photoURL && <img
                            className='w-full h-full rounded-[50%]'
                            src={userData?.photoURL}
                        />}
                    </div>
                    <div className='mt-[30px] text-[26px] font-bold'>{userData?.displayName}</div>
                    <div
                        onClick={onLogout}
                        className='mt-[71px] flex items-center gap-2 text-[18px] cursor-pointer'>
                        <FiLogOut />
                        <div className='font-semibold'>Se déconnecter</div>
                    </div>
                </div>
            </Transition>
        </div>
    )
}

export default MobileMenu
import * as React from 'react'
import { MavienModalProps } from '../../@types/appTypes'
import { CloseIcon } from '../../assets/js_svgs'
import MavienModal from '../UI/MavienModal'

import submitFormImg from '../../assets/images/submitForm.png'
import AppButton from '../UI/AppButton'

type ClickSourceType = undefined | 'other' | 'supprimerButton'

interface Props extends MavienModalProps {
    onProceed: () => void;
    clickSource: ClickSourceType
}

const SubmitFormModal = (props: Props) => {

    return <MavienModal
        onClose={() => props.onClose()}
        show={props.show}
        backdropClose={false}
    >
        <div
            className='w-[fit-content] ml-[auto]'
            onClick={() => props.onClose()}
        >
            <CloseIcon />
        </div>
        <div className='ml-[10px] md:ml-[70px]'>
            <div className=' w-[143px] md:w-[197px] h-[130px] md:h-[143px] md:w-[214px] h-[130px] md:h-[180px] mt-[16px] mb-[20px] md:mb-[24px]'>
                <img className='h-full w-full object-contain'
                    src={submitFormImg}
                />
            </div>
            <div className='mb-[10px] font-[600] text-[26px] md:text-[32px] leading-[38px]'>
                {
                    props.clickSource == 'supprimerButton' ? 'Bientôt disponible' : 'On a besoin de votre aide !'
                }
            </div>
            <div className='mb-[30px] md:mb-[24px] text-[#66677A] text-[16px] md:text-[18px]'>
                Prenez 2 minutes pour répondre à 6 questions afin de nous aider à développer la fonctionnalité de suppression au plus vite. On compte sur vous 💜️
            </div>
            <div className='mb-[40px]'>
                <AppButton
                    title='Donner mon avis'
                    onClick={() => props.onProceed()}
                />
            </div>
        </div>
    </MavienModal>
}

export default SubmitFormModal
import * as React from 'react'
import { addClassNames } from '../../utils/functions'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    buttontype?: string,
    extraclass?: string,
    title?: string
}

const AppButton = (props: Props) => {
    const { buttontype, extraclass, title } = props

    let primary_class = 'font-outfit text-white bg-mavien-purple hover:bg-[#431be4] disabled:hover:!bg-sky-blue disabled:opacity-50 p-2 font-[400] rounded-[100px] text-[18px] flex justify-center items-center h-[54px] min-w-[200px]'

    return (<button
        className={
            addClassNames(
                primary_class,
                extraclass ? extraclass : ''
            )
        }
        {...props}
    >
        {
            title ? title :
                props.children}
    </button>)
}

export default AppButton
import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#a)">
            <path d="M0 0h24v24H0V0Z" fill="#F3F3F3" />
            <path d="M1.043 1.044h10.435v10.435H1.043V1.044Z" fill="#F35325" />
            <path d="M12.523 1.044h10.435v10.435H12.523V1.044Z" fill="#81BC06" />
            <path d="M1.043 12.521h10.435v10.435H1.043V12.522Z" fill="#05A6F0" />
            <path d="M12.523 12.521h10.435v10.435H12.523V12.522Z" fill="#FFBA08" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
)

export default SvgComponent

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { baseQueryWithReauth } from "./customBaseQuery";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Profile", "Account"],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => {
        return {
          url: "auth/register",
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const { useLoginMutation } = userApi;

import * as React from 'react'

import AppButton from '../components/UI/AppButton'

import onboard2 from '../assets/images/onboard2.png'

// import trophyImage from '../assets/images/trophy.png'
import { Link, useNavigate } from 'react-router-dom'

const Onboarding2 = () => {
    const navigate = useNavigate();

    return <div className='flex flex-col items-center sm:justify-center flex-1'>
        <div className='text-center w-[90%] sm:w-[50%] flex flex-col items-center '>
            <div className='text-[#0304244D] text-[18px] font-light mt-[40px] sm:mt-[56px]'>
                Étape 2
            </div>
            <div className='w-[230px] h-[250px] md:w-[281px] md:[h-310px] mt-[12px] sm:mt-[14px]' >
                <img src={onboard2}
                    className='w-full h-full object-contain'
                />
            </div>
            <div className='mt-[20px] font-[500] text-[32px] leading-[37px] md:text-[40px] md:leading-[48px]'>
                Nous vous aidons à <span className='text-mavien-purple block'>reprendre le contrôle</span>
            </div>
        </div>
        <AppButton
            title='Suivant'
            extraclass='mt-[30px] sm:mt-[40px]'
            onClick={() => navigate('/signup')}
        />
    </div>
}

export default Onboarding2
import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={26}
        height={26}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M13.003 15.167a2.167 2.167 0 1 0 0-4.334 2.167 2.167 0 0 0 0 4.334ZM17.596 8.407a6.499 6.499 0 0 1 0 9.197m-9.186-.01a6.5 6.5 0 0 1 0-9.198M20.662 5.34a10.833 10.833 0 0 1 0 15.318m-15.318 0a10.833 10.833 0 0 1 0-15.318"
            stroke="#6640FF"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default SvgComponent

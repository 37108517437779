import React from 'react';
import { useSelector } from 'react-redux';
import {
    Navigate, useLocation, useNavigate,
} from 'react-router-dom';

const ProtectedRoute = ({ children }: any) => {
    const userState = useSelector((state: any) => state.user)

    const location = useLocation()

    const navigate = useNavigate()

    React.useEffect(() => {
        if (!userState.loggedIn) {
            navigate('/signup', {
                state: { from: location }
            })
        }
    }, [userState.loggedIn])

    // if (!userState.loggedIn) {
    //     return <Navigate to="/signup" replace state={{ from: location }} />;
    // }

    return children;
};


export default ProtectedRoute
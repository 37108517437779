import * as React from 'react'

import AppButton from '../components/UI/AppButton'

import onboard1 from '../assets/images/onboard1.png'

import { Link, useNavigate } from 'react-router-dom'

const Onboarding1 = () => {
    const navigate = useNavigate();

    return <div className='flex flex-col items-center sm:justify-center flex-1'>
        <div className='text-center w-[90%] sm:w-[50%] flex flex-col items-center mb-6'>
            <div className='mt-[40px] md:mt-[50px] text-[#0304244D] text-[18px] font-light'>
                Étape 1
            </div>
            <div className='mt-[12px] sm:mt-[14px] w-[230px] h-[250px] md:w-[342px] md:[h-310px]' >
                <img src={onboard1}
                    className='w-full h-full object-contain'
                />
            </div>
            <div className='mt-[20px] font-outfit font-[500] text-[32px] leading-[37px] md:text-[40px] md:leading-[48px]'>Vous avez perdu le contrôle de <span className='text-[#6640FF]'>vos données personnelles</span></div>

            <AppButton
                title='Suivant'
                extraclass='mt-[30px] sm:mt-[40px]'
                onClick={() => { navigate('/onboarding/2') }}
            />
        </div>

    </div>
}

export default Onboarding1
import * as React from 'react'
import { addClassNames } from '../utils/functions';
import AppButton from './UI/AppButton';


interface Props {
    data: any;
    onClick: (data: any) => void
}

const AppItem = (props: Props) => {
    const { data } = props;

    const rating = data?.rating

    const renderGrade = () => {
        let colors = 'bg-[#34A85321] text-[#34A853]'

        let processedRating = rating;

        // for X
        if (rating?.trim()?.length > 1) {
            // processedRating = 'X'
            // colors = 'bg-[#F6F6F6] text-[#7E7E7E]'

            //display nothing
            return;
        }

        if (rating?.toLowerCase() == 'a') {
            colors = 'text-[#2DAD4C] bg-[#E5FFEC]'
        }

        if (rating?.toLowerCase() == "b") {
            colors = 'bg-[#EAF8FF] text-[#33A5DD]'
        }
        if (rating?.toLowerCase() == "d") {
            colors = 'bg-[#FFEEE1] text-[#F78E40]'
        }

        if (rating?.toLowerCase() == "c") {
            colors = 'bg-[#FCECC1] text-[#A98E46]'
        }
        if (rating?.toLowerCase() == "e") {
            colors = 'bg-[#FFEBEB] text-[#D94646]'
        }

        return <div
            className={
                addClassNames(
                    'absolute top-0 right-0 flex',
                    ` justify-center items-center h-[30px] w-[30px]`,
                    `font-[400] text-[16px] leading-[16px] rounded-bl-[8px] rounded-tr-[8px]`,
                    colors
                )
            }
        >{processedRating}</div>
    }

    return <div className={
        addClassNames(
            'relative z-0 bg-white rounded-[8px] h-[250px] flex flex-col items-center py-[16px] px-[20px] justify-around shadow-md',
            //for mobile screens take max width of parent
            'w-full',
            'sm:w-[170px]'
        )
    }>
        {renderGrade()}
        <div className='w-[60px] h-[60px]'>
            <img className='h-full w-full rounded-[50%]' src={data.logo} />
        </div>
        <div className='font-[600] text-[14px] text-center'>{data.name}</div>
        <AppButton
            title='Supprimer'
            extraclass='bg-mavien-purple !h-[40px] w-full min-w-[auto] !text-[14px]'
            onClick={() => props.onClick(data)}
        />
    </div>
}

export default AppItem
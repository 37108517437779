import * as React from 'react'
import { MavienModalProps } from '../../@types/appTypes'
import { CloseIcon } from '../../assets/js_svgs'
import MavienModal from '../UI/MavienModal'

import formSubmittedImg from '../../assets/images/formSubmitted.png'

type ClickSourceType = undefined | 'other' | 'supprimerButton'

interface Props extends MavienModalProps {
    onProceed: () => void;
    clickSource: ClickSourceType
}

const FormReceivedModal = (props: Props) => {

    return <MavienModal
        onClose={props.onClose}
        show={props.show}
        backdropClose={false}
    >
        <div
            className='w-[fit-content] ml-[auto]'
            onClick={() => props.onClose()}
        >
            <CloseIcon />
        </div>
        <div className='ml-[10px] md:ml-[70px] mb-[50px] md:mb-[60px]'>
            <div className='w-[180px] mt-[16px] mb-[20px] md:mb-[24px]'>
                <img className='h-full w-full object-contain'
                    src={formSubmittedImg}
                />
            </div>
            <div className='mb-[10px] font-[600] text-[26px] md:text-[32px] leading-[38px]'>
                {
                    props.clickSource == 'supprimerButton' ? 'Bientôt disponible' : 'Nous avons déjà reçu votre formulaire'
                }
            </div>
            <div className='mb-[52px] md:mb-[30px] text-[#66677A] text-[16px] md:text-[18px]'>
                Nous étudions actuellement vos précieux retours avec attention. Vous serez informé au plus vite lorsque la fonctionnalité de suppression sera disponible.
            </div>
            <div className=' text-[#66677A] text-[14px] md:text-[16px]'>
                Si vous pensez qu’il y a eu une erreur, vous pouvez à nouveau remplir notre formulaire en suivant <span
                    onClick={() => props.onProceed()}
                    className='underline cursor-pointer'>ce lien.</span>
            </div>
        </div>
    </MavienModal>
}

export default FormReceivedModal
import React from 'react';
import { useSelector } from 'react-redux';
import {
    Navigate, useLocation, useNavigate,
} from 'react-router-dom';

const UnProtectedRoute = ({ children }: any) => {
    const userState = useSelector((state: any) => state.user)

    const location = useLocation();

    const navigate = useNavigate()

    // if (userState.loggedIn) {
    //     return <Navigate to="/steps" replace state={{ from: location }} />;
    // }

    React.useEffect(() => {
        if (userState.loggedIn) {
            // '/steps'
            navigate('/steps', {
                state: { from: location }
            })
        }
    }, [userState.loggedIn])

    return children;
};


export default UnProtectedRoute
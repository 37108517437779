import * as React from 'react'
import { MavienTextInputProps } from '../../@types/appTypes';
import { addClassNames } from '../../utils/functions';

const MavienTextInput = (props: MavienTextInputProps) => {
    const { extrainputclass, extralabelclass } = props

    let primary_class = 'text-[#030424] placeholder-[#CACACA] bg-[#fff] border-[#E7E7E7] focus:border-[#ddd] focus:text-[#030424] border-[2px] p-1 px-2 h-[54px] font-medium rounded-[40px] text-[18px] flex items-center h-[48px] min-w-[200px]'

    return (
        <div>
            {props.label ? <label className={
                addClassNames(
                    'mb-[8px] font-outift text-[18px]',
                    extralabelclass ? extralabelclass : ''
                )
            }>{props.label}</label> : undefined}
            <input
                className={
                    addClassNames(
                        'w-full font-outift hover:border-[#AAAAAA] focus:border-[#AAAAAA]',
                        primary_class,
                        extrainputclass ? extrainputclass : '',
                    )}
                {...props}
            />

        </div>
    )
}

export default MavienTextInput
import * as React from 'react'

const Logo = () => {

    return <div className='font-[600] leading-[20px] text-[18px] uppercase font-outfit'>
        Ma vie
        <div className='text-mavien-purple'>Numérique</div>
    </div>
}

export default Logo
import * as React from 'react'

import { Menu, Transition } from "@headlessui/react";
import { FiFilter, FiChevronUp, FiChevronDown } from 'react-icons/fi'
import { Field, FormikProvider, useFormik } from 'formik';
import { useGetDashboardSummaryQuery } from '../../store/rtk-query/dashboardApi';
import { OtherFilterFormProps } from '../../@types/appTypes';


const categories = [
    'Réseaux sociaux',
    'Service en ligne',
    'Boite-mail',
    'Gaming',
    'Banque en ligne'
]

const grades = [
    'Grade A',
    'Grade B',
    'Grade C',
    'Grade D',
    'Grade E',
]

let button_class = 'text-[#03042499] bg-[#fff] focus:text-[#030424] border-2 p-2 px-[20px] font-medium rounded-[40px] text-[18px] flex items-center h-[48px] min-w-[150px] justify-between'

let input_class = "w-4 h-4 text-mavien-purple mr-3 bg-gray-100 border-gray-300 rounded"

type TriggerEnum = 'click' | 'hover'

const OtherFilters = (props: OtherFilterFormProps) => {
    const { open, onClick } = props;

    let timeout: NodeJS.Timeout
    const timeoutDuration = 250

    const [trigger, setTrigger] = React.useState<undefined | TriggerEnum>(undefined)

    const menuContainerRef = React.useRef<HTMLDivElement | null>(null)

    const { data: dashboardData, isLoading: dashboardLoading } = useGetDashboardSummaryQuery({})

    const { initialValues, formik } = props;

    const { activeCats, activeGrades } = formik.values


    const handleClickOutside = (event: any) => {
        /**
         * determine if clicked element is in the popover dom tree
         */
        if (!menuContainerRef.current?.contains(event.target)) {
            //close Tooltip if its not in the dom tree

            //if its open then close
            if (open) {
                onClick()
            }
        }
    }

    React.useEffect(() => {
        //  document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mousedown", handleClickOutside)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
            //    document.removeEventListener("mousemove", handleMouseMove);
        }
    })

    const handleMouseMove = (event: any) => {
        const isInsideMenuContainer = menuContainerRef.current?.contains(event.target);

        // If the mouse is inside the menu container, trigger the onClick() function
        // if (isInsideMenuContainer && !open) {
        // onClick();
        // setTrigger("hover")
        //}

        //close when outside
        // if (!isInsideMenuContainer && open) {
        // onClick();
        // setTrigger(undefined)
        // }
    };

    const getGradeCount = (grade: string) => {
        let formatted = grade?.toLowerCase()?.replace('grade', '')?.trim();

        const ratingsCounts = dashboardData?.ratingsCounts

        let count = ratingsCounts?.[formatted?.toUpperCase()] || 0;
        return count
    }

    const renderCategories = () => {
        return (
            <div className='space-y-2'>
                <div className='font-bold'>Catégories</div>
                {categories.map((item) => {
                    const checked = activeCats.some((cat) => cat == item)
                    return <Menu.Item
                        key={item}
                    >

                        <div
                            className='flex items-center h-[30px] rounded-lg px-2 cursor-pointer'
                            style={{
                                backgroundColor: checked ? '#5BCEFF1A' : 'unset',
                                fontWeight: checked ? '600' : 'unset',
                            }}
                            onClick={() => {
                                formik.setFieldValue(
                                    'activeCats',
                                    checked
                                        ? activeCats.filter(grade => grade !== item)
                                        : [...activeCats, item]
                                );
                            }}
                        >
                            <input
                                type="checkbox"
                                checked={checked}
                                onChange={() => { }}
                                className={input_class}
                            />
                            <div>{item}</div>
                            <div className='ml-[auto] bg-[#5BCEFF33] rounded-2xl flex justify-center w-[32px] font-bold'>7</div>
                        </div>

                    </Menu.Item>
                })}
            </div>
        )
    }

    return (
        <Menu
            ref={menuContainerRef}
            as="div" className='relative z-20'>
            <Menu.Button as={React.Fragment}>
                <div
                    onClick={() => {
                        onClick();
                        setTrigger('click')
                    }}
                    className={`${button_class} cursor-pointer hidden sm:flex`}>
                    <div
                        className='w-full h-full text-[#03042499]' >
                        Filtres
                    </div>
                    <FiFilter className='text-[#B3B3B3] text-[23px]' />
                </div>
            </Menu.Button>
            <Transition
                as={React.Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                show={open}
            >
                <Menu.Items static as='div' className="" >
                    <div className="absolute z-20 mt-3 bg-white p-3 rounded-md space-y-5 min-w-[282px] border">
                        <FormikProvider value={formik}>
                            {
                                // renderCategories()
                            }

                            <div className='space-y-2'>
                                <div className='font-bold '>Grades</div>
                                {grades.map((item) => {
                                    const checked = activeGrades.some((grade) => grade == item)
                                    return <Menu.Item
                                        key={item}
                                    >

                                        <div
                                            className='flex items-center h-[30px] rounded-lg px-2 cursor-pointer'
                                            style={{
                                                backgroundColor: checked ? '#F3F0FF' : 'unset',
                                                fontWeight: checked ? '600' : 'unset',
                                            }}
                                            onClick={() => {
                                                formik.setFieldValue(
                                                    'activeGrades',
                                                    checked
                                                        ? activeGrades.filter(grade => grade !== item)
                                                        : [...activeGrades, item]
                                                );
                                            }}
                                        >
                                            <input checked={checked}
                                                onChange={() => { }}
                                                type="checkbox"
                                                value="" className={input_class} />
                                            <div>{item}</div>
                                            <div className='ml-[auto] flex justify-center w-[32px] font-[500]'>
                                                {getGradeCount(item)}
                                            </div>
                                        </div>

                                    </Menu.Item>
                                })}
                            </div>
                        </FormikProvider>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default OtherFilters
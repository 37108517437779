import * as React from 'react'
import { createBrowserRouter, Route, RouteObject, Routes } from 'react-router-dom';
//
import DefaultLayout from '../layout/default';


import Onboarding1 from '../pages/Onboarding1'
import Onboarding2 from '../pages/Onboarding2'
import SignUp from '../pages/SignUp';
//
import Steps from '../pages/Steps';
import Dashboard from '../pages/Dashboard';
//
import ProtectedRoute from './ProtectedRoute';
import UnProtectedRoute from './UnProtectedRoute';
import NotFound from '../pages/NotFound';
import CompleteReg from '../pages/CompleteReg';


const MainRouter = () => {

    const loggedOutroutes: RouteObject[] = [
        {
            path: '/',
            element: <Onboarding1 />
        },
        {
            path: '/onboarding/2',
            element: <Onboarding2 />
        },
        {
            path: '/signup',
            element: <SignUp />
        }
    ]

    const loggedInRoutes: RouteObject[] = [
        {
            path: '/dashboard',
            element: <Dashboard />
        }, {
            path: '/steps',
            element: <Steps />
        },
        {
            path: '/complete-reg',
            element: <CompleteReg />
        }
    ]

    const createRoutes = (routes: RouteObject[]) => {
        return routes.map((routeProps) => {
            return <Route
                key={routeProps.path}
                path={routeProps.path}
                element={routeProps.element}
            />
        })
    }

    return (
        <Routes>
            <Route element={
                <ProtectedRoute>
                    <DefaultLayout />
                </ProtectedRoute>
            }>
                {createRoutes(loggedInRoutes)}
            </Route>
            <Route element={
                <UnProtectedRoute>
                    <DefaultLayout />
                </UnProtectedRoute>
            }>
                {createRoutes(loggedOutroutes)}
            </Route>
            <Route element={<DefaultLayout />}>
                <Route path='*' element={<NotFound />} />
            </Route>
        </Routes>
    )
}

export default MainRouter
import * as React from 'react'

import ReactSlider from 'react-slider'

import './style.css'

import thumbImg from '../../assets/images/thumb.png'

interface Props {
    percent: number;
    onAfterChange?: ((value: number, index: number) => void) | undefined
    onChange: ((value: number, index: number) => void) | undefined;
    defaultValue: number | undefined
}

interface Step {
    position: number,
}

const StepsSlider = (props: Props) => {
    let defaultValue = props.defaultValue ? props.defaultValue : 20

    return (
        <div className="self-stretch py-[4px] rounded-md">
            <ReactSlider
                className="horizontal-slider"
                thumbClassName="example-thumb"
                trackClassName="example-track"
                onAfterChange={props.onAfterChange}
                onChange={props.onChange}
                markClassName="example-mark"
                marks={[112, 217, 320, 424, 527]}
                // marks={[16.6, 33.33, 50, 66.66, 83.33]}
                renderTrack={(props) => {

                    let right = props.style?.right as number
                    return <span
                        {...props}
                        style={{
                            ...props.style,
                            ...(props.key == "example-track-0" && props.style && {
                                right: right + 12
                            })
                        }}
                    />;
                }}
                defaultValue={defaultValue}
                renderThumb={(props, state) => {
                    return <div  {...props}
                    >
                        <div><span></span></div>
                        {/* <img src={thumbImg} className='h-full w-full ' /> */}
                    </div>;
                }}
                min={20}
                max={615}
            />
            <div className="hidden sm:block mt-2 text-[#0304244D] text-[14px]">
                Pousser et glisser vers la droite
            </div>
        </div>
    )
}

export default StepsSlider
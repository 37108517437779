import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        {...props}
    >
        <path
            stroke="#BBB5D3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M11 1 1 11M1 1l10 10"
        />
    </svg>
)
export default SvgComponent
